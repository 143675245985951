import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Category = async () => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const singleSubCategory = async (id) => {
  try {
    return await getData(`/without-login/subcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const popularCategory = async () => {
  try {
    return await getData(`/without-login/all-pupular-category`);
  } catch (error) {
    console.log(error);
  }
};

export const Products = async (category_id = "", sub_category_id = "", child_sub_category_id = "", seller_id = "") => {

  try {
    let url = "/without-login/product/filter";

    if (category_id && sub_category_id && child_sub_category_id && seller_id) {
      url += `?category_id=${category_id}&sub_category_id=${sub_category_id}&child_sub_category_id=${child_sub_category_id}&seller_id=${seller_id}`;
    } else if (category_id) {
      url += `?category_id=${category_id}`;
    } else if (sub_category_id) {
      url += `?sub_category_id=${sub_category_id}`;
    } else if (child_sub_category_id) {
      url += `?child_sub_category_id=${child_sub_category_id}`;
    }

    if (seller_id) {
      url += `&seller_id=${seller_id}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getProductDetail = async (id) => {
  try {
    return await getData(`/without-login/product/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductVariant = async (id) => {
  try {
    return await getData(`/without-login/product/variants/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getVariant = async (id) => {
  try {
    return await getData(`/without-login/product/variant/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const allProductReviewGet = async (id) => {
  try {
    return await getData(`/without-login/home/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewGet = async (id) => {
  try {
    return await getData(`/review/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewPost = async (data) => {
  try {
    return await postData(`/review/add`, data);
  } catch (error) {
    console.log(error);
  }
};


export const productList = async () => {
  try {
    return await getData(`/without-login/product`);
  } catch (error) {
    console.log(error);
  }
};


