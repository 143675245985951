import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "./SelectByCategories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";

import { Category, Products } from "../../../utils/apis/Product/Product";

library.add(fas);




const SelectByCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show
  const [showAll, setShowAll] = useState(false); // State to track whether to show all cards or not

  const navigate = useNavigate();

  const handleNavItemClick = (selectedLink) => {
    navigate(`/${selectedLink}`); // Navigate to the selected link
  };


  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setNumToShow(showAll ? 8 : products.length);
    if (showAll) {
      // If currently showing all, reduce the number of rows displayed
      setNumToShow(4);
    } else {
      // If currently not showing all, show all rows
      setNumToShow(category.length);
    }
  };



  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);
  const [category, setCategory] = useState([]);
  
  const getCategory = async () => {
    setShimmerLoader(true);
    const res = await Category();
    if (res?.success) {
      setCategory(res.data);
      getProduct(res?.data[0]?.id);
      setShimmerLoader(false);
    }
  }

  const [products, setProducts] = useState([]);
  const getProduct = async (category_id) => {
    const res = await Products(category_id);
    if (res?.success) {
      setProducts(res?.data?.data);
    }
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <section className="select-categories">
      <Container>
        <div className="mb-4">
          <h2 className="title">Select by Category</h2>
        </div>
        {/* tab */}
        <Tab.Container id="left-tabs-example" defaultActiveKey={"first"}>
          <Row className="me-0 ms-0 ">
            <Nav variant="pills" className="flex-row p-0">
              <Swiper
                className="mySwiper1 "

                onSwiper={(swiper) => setSwiperInstance(swiper)}
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                  },
                  420: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },

                  768: {
                    slidesPerView: 3.5,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 5.5,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 6.5,
                    spaceBetween: 10,
                  },
                }}
              >
                {category?.map((slide, index) => (
                  <SwiperSlide key={index} className="product-slide">
                    <Nav.Item>
                      <Nav.Link eventKey={index == 0 ? "first" : slide?.name} onClick={() => getProduct(slide?.id)}>
                        <Popular_Product_Card
                          className="product-image"
                          content={slide?.name}
                          image={IMG_URL + slide?.image}
                          imgClassName="category-image"
                        />
                        <div className="triangle-right"></div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </Row>



          {category?.map((slide, index) => (
            <Tab.Content>
              <Tab.Pane eventKey={index == 0 ? "first" : slide?.name}>
                <div className="desktop_view_product_category_slider">
                  <Row>
                    {products.slice(0, numToShow).map((category, index) => (
                      <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                        <Product_Card
                          image={IMG_URL + category?.image1}
                          text={category?.name}
                          product_id={category?.id}
                          subText={category?.short_description}
                          price={category?.variants[0]?.s_price}
                          variant_id={category?.variants[0]?.id}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>

                {/* desktop_view_product_category_slider end */}

                {/* mobile_view_product_category_slider start */}

                <div className="mobile_view_product_category_slider">
                  <Row className="ms-0 me-0">
                    <Swiper
                      className="select-categories-slider mySwiper"
                      modules={[Pagination]}
                      pagination={{
                        dynamicBullets: true,
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        420: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        486: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        576: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                      }}
                    >
                      {products.slice(0, numToShow).map((category, index) => (
                        <SwiperSlide>
                          <Product_Card
                            image={IMG_URL + category?.image1}
                            text={category?.name}
                            product_id={category?.id}
                            subText={category?.short_description}
                            price={category?.variants[0]?.s_price}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Row>
                </div>

              </Tab.Pane>
            </Tab.Content>
          ))}

        </Tab.Container>

        {(numToShow < products.length || showAll) && (
          <div className="view-all mb-5" onClick={toggleShowAll}>
            <p>{showAll ? "View Less" : "View All"}</p>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SelectByCategories;
